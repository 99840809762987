<template>
    <div class="content">
        <el-upload ref="videoUpload" :action='action' :before-upload="beforeAvatarUpload" :on-preview="handlePreview"
            :before-remove="beforeRemove" :on-remove="handleRemove" :on-success="handleSuccess"
            :on-exceed="handleExceed" drag :limit="limit" :file-list="fileList">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
                将视频文件拖到此处，或
                <em>点击上传</em>
            </div>
            <div slot="tip" class="el-upload__tip">上传文件大小不能超过 500M</div>
        </el-upload>

        <el-progress style="width: 300px;" v-show="showProgress" :text-inside="true" :stroke-width="15"
            :percentage="progress"></el-progress>
    </div>
</template>
<script>
import { client, getFileNameUUID } from '../src/common/js/oss.js'

export default {
    props: {
        showProgress: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // action: 'https://xinouyou.oss-cn-shanghai.aliyuncs.com',
            action: "",
            fileList: [],//文件列
            // showProgress: false,//进度条的显示
            dataObj: {
                region: '',
                accessKeyId: '',
                accessKeySecret: '',
                bucket: '',
            }, //存签名信息
            progress: 0, //进度条数据
            limit: 1
        }
    },
    created() {
        let data = JSON.parse(localStorage.getItem('initializeData'))
        this.dataObj.region = data.ali_oss_region
        this.dataObj.accessKeyId = data.ali_oss_access_id
        this.dataObj.accessKeySecret = data.ali_oss_access_key
        this.dataObj.bucket = data.ali_oss_bucket
        this.action = 'https://' + data.ali_oss_bucket + '.' + data.ali_oss_region + '.aliyuncs.com'

    },

    methods: {
        // 文件超出个数限制时的钩子
        handleExceed(files, fileList) {
            this.$message.warning(`每次只能上传 ${this.limit} 个文件`);
        },
        // 点击文件列表中已上传的文件时的钩子
        handlePreview(file) { },
        // 删除文件之前的钩子 
        beforeRemove(file, fileList) {
            // return this.$confirm(`确定移除 ${file.name}？`);
            this.handleRemove()
        },
        // 文件列表移除文件时的钩子
        handleRemove(file, fileList) {
            this.showProgress = false
        },
        // 文件上传成功时的钩子
        handleSuccess(response, file, fileList) {
            this.fileList = fileList;
        },
        //文件上传前的校验
        beforeAvatarUpload(file) {
            console.log(file)
            let that = this
            // 获取视频时长
            var url = URL.createObjectURL(file);
            var audioElement = new Audio(url);
            var duration;
            audioElement.addEventListener("loadedmetadata",
                function () {
                    duration = parseInt(audioElement.duration); //时长为秒，取整          
                    console.log(duration);
                    that.$emit('videoDuration' , duration)
                });

            // console.log(file.size / 1024 / 1024 < 500)
            const isLt500M = file.size / 1024 / 1024
            if (["video/mp4", "video/quicktime", "video/MP4"].indexOf(file.type) == -1) {
                that.$message.error("请上传正确的视频格式");
                return false;
            }
            if (isLt500M > 500) {
                that.$message.error("上传视频大小要小于500M哦!");
                return false;
            }
            that.Upload(file)
        },
        Upload(file) {
            const that = this;
            async function multipartUpload() {
                let temporary = file.name.lastIndexOf(".");
                let fileNameLength = file.name.length;
                let fileFormat = file.name.substring(
                    temporary + 1,
                    fileNameLength
                );
                let fileName = getFileNameUUID() + "." + fileFormat;
                client(that.dataObj)
                    .multipartUpload(`videoTest/${fileName}`, file, {
                        progress: function (p) {
                            //p进度条的值
                            // console.log(p);
                            // that.showProgress = true;
                            that.$emit('showP', true)

                            that.progress = Math.floor(p * 100);
                        }
                    })
                    .then(result => {
                        //上传成功返回值，可针对项目需求写其他逻辑
                        let path = result.res.requestUrls[0]
                        if (path.indexOf("?") != -1) {
                            path = path.split("?")[0];
                            // console.log(path);
                            // 这是OSS传回来的数据
                            that.$emit('videoPath', path)
                        } else {
                            that.$emit('videoPath', path)
                        }
                    })
                    .catch(err => {
                        console.log("err:", err);
                    });
            }
            multipartUpload();
        }
    }
}
</script>
<style lang="less" scoped>
.content {
    .content {
        /deep/.el-upload-list {
            width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

}
</style>